<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2019/10/22
 * desc: 地图-热力值展示
 */

import Vue from 'vue';

export default {
  name: 'heat-map',
  props: {
    heatmapData: { type: Array, default() { return []; } }
  },
  mounted() {
    this.initMap();
  },

  data() {
    return {
    };
  },

  methods: {
    initMap() {
      const map = new AMap.Map('container', {
        features: ['bg', 'road'],
        resizeEnable: true,
        center: [109.564086, 18.430857],
        zoom: 9,
        viewMode: '2D',
        dragEnable: true, // 禁止拖动
        pitch: 50,
        showZoomBar: true
      });
      let heatmap;
      map.plugin(['AMap.Heatmap'], () => {
        // 初始化heatmap对象
        heatmap = new AMap.Heatmap(map, {
          radius: 25, // 给定半径
          opacity: [0, 0.8],
          gradient: {
            0.5: 'blue',
            0.65: 'rgb(117,211,248)',
            0.7: 'rgb(0, 255, 0)',
            0.9: '#ffea00',
            1.0: 'red'
          }
        });
        // 设置数据集
        heatmap.setDataSet({
          data: this.heatmapData,
          max: 5
        });
      });
    }
  },

  watch: {
    heatmapData: {
      handler() {
        this.initMap();
      },
      deep: true
    }
  }
};
</script>

<template>
<div class="module_heat_map">
  <div id="container"></div>

  <div class="no_outbreak" v-if="heatmapData.length === 0">无疫情</div>
</div>
</template>

<style lang="less" scoped>


.module_heat_map {
  padding: 0 2rem;
  height: 26rem;
  position: relative;
  #container {
    width: 1160px;
    height: 100%;
    margin: 20px auto;
  }
  .no_outbreak {
    position: absolute;
    width: 60%;
    height: 12rem;
    top: 20%;
    left: 20%;
    border: 6px solid #777;
    color: #777;
    border-radius: .63rem;
    z-index: 999;
    text-align: center;
    font-size: 3.75rem;
    line-height: 12rem;
  }
}

</style>
