<template>
  <div class="publicService">
    <div class="breadWrap">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>南繁管理</el-breadcrumb-item>
        <el-breadcrumb-item>生物监控</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="message">
      <img src="../../assets/images/Management/icon_warning@3x.png" alt="">
       <el-carousel height="35px" direction="vertical" :autoplay="true">
          <el-carousel-item>
            <p>{{this.roll.noticeDate}}{{this.roll.baseName}}{{this.roll.describes}}</p>
          </el-carousel-item>
          <el-carousel-item>
            <p>{{this.roll.noticeDate}}{{this.roll.baseName}}{{this.roll.describes}}</p>
          </el-carousel-item>
          <el-carousel-item>
            <p>{{this.roll.noticeDate}}{{this.roll.baseName}}{{this.roll.describes}}</p>
          </el-carousel-item>
        </el-carousel>
      <!-- <p>{{this.roll.noticeDate}}{{this.roll.baseName}}{{this.roll.describes}}</p> -->
    </div>
    <!-- <div class="location">
      <i class="el-icon-location-information" :style="{color:'#5d73fb'}"></i>
      <p>{{this.roll.baseName}}</p>
    </div> -->
    <div class="halving"></div>
    <div class="jobContent">
      <div></div>
      <div>生物监控数据</div>
      <div @click="goto">上报列表</div>
    </div>
    <div class="organismsBox">
      <el-row class="timeDataBox">
        <el-date-picker
          v-model="timeData"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change='monitoringdata'
        ></el-date-picker>
        <el-radio-group v-model="radio" @change="handleCheckAllChange" :style="{marginLeft:'30px'}">
          <el-radio :label="1">全部</el-radio>
          <el-radio :label="2">近一周</el-radio>
          <el-radio :label="3">近一月</el-radio>
        </el-radio-group>
      </el-row>
      <el-row type="flex" class="organismsDetail">
        <el-col class="od_block" :span="6">
          <div class="od_block_inside" @click="goto">
            <el-row class="odbi_half">
              <el-col :span="12" class="odbih_imgBox">
                <span>{{monitoring[0].num}}</span>起
              </el-col>
              <el-col :span="12" class="odbih_imgBox">
                <img src="../../../public/static/img/management/icon_sujk1@2x.png" alt />
              </el-col>
            </el-row>
            <el-row class="odbi_half">转基因生物安全监管</el-row>
          </div>
        </el-col>
        <el-col class="od_block" :span="6" >
          <div class="od_block_inside" @click="goto">
            <el-row class="odbi_half">
              <el-col :span="12" class="odbih_imgBox">
                <span>{{monitoring[1].num}}</span>起
              </el-col>
              <el-col :span="12" class="odbih_imgBox">
                <img src="../../../public/static/img/management/icon_sujk2@2x.png" alt />
              </el-col>
            </el-row>
            <el-row class="odbi_half">重大病虫草鼠害防治</el-row>
          </div>
        </el-col>
        <el-col class="od_block" :span="6">
          <div class="od_block_inside" @click="goto">
            <el-row class="odbi_half">
              <el-col :span="12" class="odbih_imgBox">
                <span>{{monitoring[4].num}}</span>起
              </el-col>
              <el-col :span="12" class="odbih_imgBox">
                <img src="../../../public/static/img/management/icon_sujk3@2x.png" alt />
              </el-col>
            </el-row>
            <el-row class="odbi_half">生物安全普查</el-row>
          </div>
        </el-col>
        <el-col class="od_block" :span="6">
          <div class="od_block_inside" @click="goto">
            <el-row class="odbi_half">
              <el-col :span="12" class="odbih_imgBox">
                <span>{{monitoring[2].num}}</span>起
              </el-col>
              <el-col :span="12" class="odbih_imgBox">
                <img src="../../../public/static/img/management/icon_sujk4@2x.png" alt />
              </el-col>
            </el-row>
            <el-row class="odbi_half">外来入侵生物预警与控制</el-row>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" class="organismsDetail">
        <el-col class="od_block" :span="6">
          <div class="od_block_inside" @click="goto">
            <el-row class="odbi_half">
              <el-col :span="12" class="odbih_imgBox">
                <span>{{monitoring[3].num}}</span>起
              </el-col>
              <el-col :span="12" class="odbih_imgBox">
                <img src="../../../public/static/img/management/icon_sujk5@2x.png" alt />
              </el-col>
            </el-row>
            <el-row class="odbi_half">检疫性有害生物调查</el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="jobContent">
      <div></div>
      <div>生物预警数据</div>
    </div>
    <div class="organismsBox">
      <el-row class="timeDataBox">
        <el-date-picker
          v-model="timeDatas"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change='mapdata'
        ></el-date-picker>
      </el-row>
      <el-radio-group v-model="radios" @change="handleCheckAllChanges" :style="{marginLeft:'30px'}">
        <el-radio :label="1">全部</el-radio>
        <el-radio :label="2">近一周</el-radio>
        <el-radio :label="3">近一月</el-radio>
      </el-radio-group>
    </div>
    <div class="publice">
        <div class="flex publiceCommon">
            <div class='title'>生物监控类别：</div>
            <div  class='typeName' @click='onLocationCon(index, item.id)' :class='locationActive==index?"active":""' v-for='(item, index) in locationList' :key='index+"l"'>{{item.name}}</div>
        </div>
         <heat-map :heatmapData="heatmap_data" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col, DatePicker, Carousel, CarouselItem } from "element-ui";


Vue.use(Row)
  .use(Col)
  .use(DatePicker).use(Carousel).use(CarouselItem);
import heatMap from './heatMap.vue';
export default {
  name: "biological",
  data() {
    return {
      locationid: 0,
      monitoring: '',
      roll: {},
      heatmap_data: [],
      locationActive: 0,
      endDate: '',
      startDate: '',
      endDates: '',
      startDates: '',
      timeData: "",
      timeDatas: '',
      radio: 1,
      radios: 1,
      locationList: [{
                id: 0,
                name: '全部'
            }, {
                id: 4,
                name: '检疫性有害生物调查'
            }, {
                id: 1,
                name: '转基因生物安全监管'
            }, {
                id: 5,
                name: '生物安全普查'
            }, {
                id: 2,
                name: '重大病虫草鼠防治'
            }, {
                id: 3,
                name: '外来入侵生物预警与控制'
            }]
    };
  },
  created() {
    this.selectMonitorRoll();
    this.MonitorType();
    this.selectMonitorSurvey();
  },
   components: {
    'heat-map': heatMap
  },
  methods: {
    handleCheckAllChange(val) {
      switch (val) {
        case 1:
          this.startDates = "";
          this.endDates = "";
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
        case 2:
          this.startDates = this.getDay(-7);
          this.endDates = this.timestampToTime();
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
        case 3:
          this.startDates = this.getDay(-30);
          this.endDates = this.timestampToTime();
          this.timeData = "";
          this.selectMonitorSurvey();
          break;
      }
    },
    getDay(day) {
      // 获取近一周、近一个月的日期
      let today = new Date();
      let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      let tYear = today.getFullYear();
      let tMonth = today.getMonth();
      let tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      let m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    //获取当前时间
    timestampToTime() {
      const date = new Date(),
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    handleCheckAllChanges(val) {
      switch (val) {
        case 1:
          this.startDate = "";
          this.endDate = "";
          this.timeDatas = "";
          this.MonitorType();
          break;
        case 2:
          this.startDate = this.getDays(-7);
          this.endDate = this.timestampToTimes();
          this.timeDatas = "";
          this.MonitorType();
          break;
        case 3:
          this.startDate = this.getDays(-30);
          this.endDate = this.timestampToTimes();
          this.timeDatas = "";
          this.MonitorType();
          break;
      }
    },
    getDays(day) {
      // 获取近一周、近一个月的日期
      let atodays = new Date();
      let atargetday_milliseconds = atodays.getTime() + 1000 * 60 * 60 * 24 * day;
      atodays.setTime(atargetday_milliseconds); //注意，这行是关键代码
      let atYears = atodays.getFullYear();
      let tMonths = atodays.getMonth();
      let tDates = atodays.getDate();
      tMonths = this.doHandleMonths(tMonths + 1);
      tDates = this.doHandleMonths(tDates);
      return atYears + "-" + tMonths + "-" + tDates;
    },
    doHandleMonths(month) {
      let im = month;
      if (month.toString().length == 1) {
        im = "0" + month;
      }
      return im;
    },
    //获取当前时间
    timestampToTimes() {
      const date = new Date(),
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ";
      return Y + M + D;
    },
    monitoringdata(){
      this.radio = 0;
      if (this.timeData != null) {
        let monthe = this.timeData[0].getMonth() + 1;
        let mondatea = this.timeData[0].getDate();
        if (monthe < 10) {
          monthe = "0" + monthe;
        }
        if (mondatea < 10) {
          mondatea = "0" + mondatea;
        }
        this.startDates =
        this.timeData[0].getFullYear() + "-" + monthe + "-" + mondatea;
        let monthse = this.timeData[1].getMonth() + 1;
        let mondatesa = this.timeData[1].getDate();
        if (monthse < 10) {
          monthse = "0" + monthse;
        }
        if (mondatesa < 10) {
          mondatesa = "0" + mondatesa;
        }
        this.endDates =
        this.timeData[1].getFullYear() + "-" + monthse + "-" + mondatesa;
        } else {
          this.startDates = "";
          this.endDates = "";
        }
      this.selectMonitorSurvey();
    },
    mapdata(){
      this.radios = 0;
      if (this.timeDatas != null) {
        let month = this.timeDatas[0].getMonth() + 1;
        let mondate = this.timeDatas[0].getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (mondate < 10) {
          mondate = "0" + mondate;
        }
        this.startDate =
        this.timeDatas[0].getFullYear() + "-" + month + "-" + mondate;
        let months = this.timeDatas[1].getMonth() + 1;
        let mondates = this.timeDatas[1].getDate();
        if (months < 10) {
          months = "0" + months;
        }
        if (mondates < 10) {
          mondates = "0" + mondates;
        }
        this.endDate =
        this.timeDatas[1].getFullYear() + "-" + months + "-" + mondates;
      } else {
        this.startDate = "";
        this.endDate = "";
      }
      this.MonitorType();
    },
    onLocationCon(idx, id) {
        this.locationActive = idx;
        this.locationid = id;
        this.MonitorType();
    },
    goto(){
     this.$router.push({
        path: "/downLoad"
      });
    },
  // 滚动条列表
    async selectMonitorRoll() {
        const api = this.$fetchApi.selectMonitorRoll.api;
        const data = await this.$fetchData.fetchPost('', api, "json");
        if (data.code === '200' && data.result) {
          this.roll = data.result;
          this.roll.noticeDate = this.roll.noticeDate.substring(0, 10);
        }
    },
    // 生物预警监控
    async MonitorType() {
        const api = this.$fetchApi.MonitorType.api;
        const data = await this.$fetchData.fetchPost({
          	endDate: this.endDate,
            monitorType: this.locationid,
            startDate: this.startDate
        }, api, "json");
        if (data.code === '200' && data.result) {
          this.heatmap_data = data.result;
        }
    },
     // 生物监控数据
    async selectMonitorSurvey() {
        const api = this.$fetchApi.selectMonitorSurvey.api;
        const data = await this.$fetchData.fetchPost({
          	endDate: this.endDates,
            userId: '',
            startDate: this.startDates
        }, api, "json");
        if (data.code === '200' && data.result) {
          this.monitoring = data.result;
         console.log(data);
        }
    }
  }
};
</script>

<style lang="less" scoped>
.publicService {
  font-size: 16px;
  line-height: 16px;
  color: #333;
  .breadWrap {
    border-bottom: 1px solid #ccc;
    .el-breadcrumb {
      width: 1200px;
      margin: 20px auto;
      font-size: 16px;
    }
  }
}
.message {
  width: 1154px;
  height: 35px;
  background-color: #ffeeee;
  border-radius: 0px 18px 18px 0px;
  margin: 22px auto;
  position: relative;
  img{
    width: 35px;
    height: 35px;
    position: absolute;
    left: -17px;
  }
  p {
    line-height: 35px;
    padding-left: 69px;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #5c5c5c;
  }
}
.location {
  width: 1154px;
  display: flex;
  margin: auto;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  color: #5c5c5c;
  p {
    margin-left: 15px;
  }
}
.halving {
  width: 1180px;
  height: 1px;
  background-color: #d9d9d9;
  border-radius: 1px;
  margin: 42px auto;
}
.jobContent {
  width: 1181px;
  margin: 2px auto;
  overflow: hidden;
  line-height: 36px;
  div:nth-child(1) {
    width: 6px;
    height: 20px;
    background-color: #265ae1;
    float: left;
    margin-right: 10px;
    margin-top:8px; 
  }
  div:nth-child(2) {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #333333;
    float: left;
  }
  div:nth-child(3){
    width: 90px;
    height: 36px;
    background-color: #265ae1;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    line-height: 36px;
    float: right;
  }
}
.organismsBox {
  width: 1200px;
  margin: auto;
  margin-bottom: 40px;
  .timeDataBox {
    margin: 40px 0 40px 20px;
    padding: 0;
  }
}

.od_block {
  height: 160px;
  position: relative;
}

.od_block_inside {
  position: absolute;
  top: 10px;
  left: 20px;
  right: 20px;
  bottom: 10px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.od_block_inside:hover {
  transform: scale(1.2);
  box-shadow: 0 0 10px #ccc;
}

.odbi_half {
  height: 70px;
  text-align: center;
  line-height: 70px;
}

.odbih_imgBox {
  height: 100%;
  color: #265ae1;
  font-size: 14px;
  position: relative;
}

.odbih_imgBox span {
  font-size: 36px;
  margin-right: 10px;
}

.odbih_imgBox img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.publice{
    width: 1160px;
    border: 1px solid #d9d9d9;
     margin: 0 auto;
    margin-bottom: 100px;

}
.publiceCommon{
    display: flex;
    padding:13px 22px 13px 12px;
    border-bottom:1px dashed #d9d9d9; 
}
.typeName{
    padding:0 5px;
    height:30px;
    line-height: 30px;
    margin-right:10px;
    float: left;
    font-family: MicrosoftYaHei;
	font-size: 16px;
	font-weight: normal;
	letter-spacing: 1px;
	color: #333333;
}
.title{
    width: 115px;
    margin-right:20px;
    height:30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
}
.active{
    color:#265ae1;
}
.module_heat_map{
  padding: 0;
}
/deep/.amap-container{
  margin: 0 !important;
}
</style>